import {useEffect, useLayoutEffect, useState} from 'react';

import {isNil} from 'lodash';
import {useReactiveVar} from '@apollo/client';
import {Route, Switch} from 'react-router-dom';
import {ThemeProvider} from '@emotion/react';

import {chilipiper} from '@renofi/analytics';
import {Analytics, Auth} from '@renofi/components';
import {
  breakpoint,
  constants,
  isLeadQualified,
  isQueryParamSet,
  useCookies,
  useFeatureFlagEvents,
  useLocalStorage,
  useScrollToTopBetweenRoutes,
  useUTMTags,
} from '@renofi/utils';
import {initGooglePlaces} from '@renofi/utils/src/places';
import {useUnleashClient} from '@renofi/utils/src/feature-flags';
import {isFakeLead} from '@renofi/utils/src/lead';

import {leadVar, loanProductsVar} from '../api/cache';
import {useAutoSchedule} from '../hooks';

import App from './App';

const theme = {
  breakpoints: [`${breakpoint}px`],
};

const AppWrapper = () => {
  const lead = useReactiveVar(leadVar);
  const {email, phone, firstName, lastName, designProjectDetails} = lead || {};
  const isQualified = isLeadQualified(lead);
  const [scheduleStorage] = useLocalStorage('renofi:schedule');
  const loanProducts = useReactiveVar(loanProductsVar);

  const isScheduleParam = isQueryParamSet('schedule') || scheduleStorage;
  const [initedWithScheduleParam] = useState(isScheduleParam);

  const {persistUTMTags} = useUTMTags(constants.UTM_COOKIE_KEYS.renofi);
  const {cookie: cookieId} = useCookies('renofi_id');
  const unleashClient = useUnleashClient();
  const calendarElement = document.querySelector('#calendar');
  const calendarExists =
    !isNil(calendarElement) && calendarElement?.children?.length > 0;

  useFeatureFlagEvents(unleashClient, 'Dashboard');

  useScrollToTopBetweenRoutes();

  useAutoSchedule(lead);

  useEffect(() => {
    persistUTMTags();
  }, []);

  useLayoutEffect(() => {
    if (isLeadReady() && !isFakeLead(lead)) {
      initCalendar();
    }
  }, [
    email,
    phone,
    firstName,
    lastName,
    initedWithScheduleParam,
    isQualified,
    designProjectDetails,
  ]);

  useLayoutEffect(() => {
    if (!calendarExists && isLeadReady()) {
      initCalendar();
    }
  }, [calendarExists]);

  useEffect(() => {
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    if (apiKey) setTimeout(() => initGooglePlaces(apiKey), 5000);
  }, []);

  function isLeadReady() {
    return (
      email &&
      phone &&
      firstName &&
      lastName &&
      (isQualified || initedWithScheduleParam)
    );
  }

  function initCalendar() {
    const calendar = document.querySelector(`#calendar`);
    if (calendar) calendar.remove();

    const placeholder = document.createElement('div');
    placeholder.setAttribute('id', 'calendar');
    document.body.appendChild(placeholder);

    chilipiper.initSchedule({
      lead,
      domElement: `#calendar`,
      router: chilipiper.getRouterByLead(lead),
      analyticsPrefix: 'Dashboard',
      onClose: () => {
        document.dispatchEvent(new CustomEvent('CP-Calendar-Close'));
      },
      onSuccess: ({slot}) => {
        document.dispatchEvent(
          new CustomEvent('CP-Calendar-Success', {detail: slot}),
        );
      },
    });
  }

  return (
    <Analytics lead={lead} loanProducts={loanProducts}>
      <ThemeProvider theme={theme}>
        <Switch>
          <Route exact path="/auth">
            <Auth failedPath={`/${cookieId}/loan-options`} />
          </Route>
          <Route path={['/:id', '/']}>
            <App cookieId={cookieId} openWithSchedule={isScheduleParam} />
          </Route>
        </Switch>
      </ThemeProvider>
    </Analytics>
  );
};

export default AppWrapper;
