import React from 'react';

import {Flex, Box, Text} from 'rebass';
import {useReactiveVar} from '@apollo/client';

import {Button, Toggle} from '@renofi/components';
import {ScheduledCallSuccess} from '@renofi/modules';
import {isLeadQualified, hasCRMOwner, constants} from '@renofi/utils';
import {gray} from '@renofi/utils/src/colors';
import {formatPhoneNumber} from '@renofi/utils/src/format';

import useSchedule from '../../hooks/useSchedule';
import useModals from '../../hooks/useModals';
import {crmOwnerVar, leadVar} from '../../api/cache';
import AdvisorThumb from '../../components/AdvisorThumb';
import AdvisorName from '../../components/AdvisorName';

import {ReactComponent as DividerIcon} from './img/or-call.svg';
import {ReactComponent as PhoneIcon} from './img/phone.svg';

const ScheduleBox = () => {
  const lead = useReactiveVar(leadVar);
  const crmOwner = useReactiveVar(crmOwnerVar);
  const isQualified = isLeadQualified(lead);
  const {callDetails, isCallScheduled} = useSchedule();
  const {setModal} = useModals();

  return (
    <>
      <Toggle show={isCallScheduled}>
        <ScheduledCallSuccess
          callDetails={callDetails}
          rescheduleEnabled
          onReschedule={() => setModal(constants.MODAL_TYPES.SCHEDULE)}
        />
      </Toggle>

      <Toggle show={!isCallScheduled && isQualified}>
        <Text fontSize={18} mb={32}>
          <strong>Get in touch with your RenoFi advisor to learn more</strong>
        </Text>

        <Toggle show={hasCRMOwner(crmOwner)}>
          <Flex mb={24}>
            <AdvisorThumb mt="4px" size={42} />
            <Box ml={16}>
              <Text color={gray} fontWeight={100}>
                Your RenoFi advisor
              </Text>
              <Text fontSize={24}>
                <strong>
                  <AdvisorName />
                </strong>
              </Text>
            </Box>
          </Flex>
        </Toggle>

        <Flex alignItems="center">
          <Button
            small
            width={180}
            onClick={() => setModal(constants.MODAL_TYPES.SCHEDULE)}>
            Schedule a call
          </Button>
          <Box mx={16} height={16}>
            <DividerIcon />
          </Box>
          <PhoneIcon />
          <Text fontSize={32} ml={16}>
            <strong>
              {crmOwner.phoneNumber
                ? formatPhoneNumber(crmOwner.phoneNumber)
                : '(855) 736-6341'}
            </strong>
          </Text>
        </Flex>
      </Toggle>
    </>
  );
};

export default ScheduleBox;
