import React from 'react';

import PropTypes from 'prop-types';
import {useReactiveVar} from '@apollo/client';

import {sendEvent} from '@renofi/analytics';
import {
  constants,
  useScreenSize,
  navbarBreakpoint,
  allowNavbarCallSchedule,
} from '@renofi/utils';

import {leadVar, setModal} from '../../api/cache';

import useAvailableNavOptions from './useAvailableNavOptions';
import NavbarDesktop from './NavbarDesktop';
import NavbarMobile from './NavbarMobile';

const Navbar = ({id, loading}) => {
  const lead = useReactiveVar(leadVar);
  const allowSchedule = allowNavbarCallSchedule(lead);
  const {isDesktop} = useScreenSize(navbarBreakpoint);

  const {mainOptions, additionalOptions, desktopOptions} =
    useAvailableNavOptions();

  function openScheduleModal() {
    sendEvent('Dashboard/Navbar-Schedule-Button-Clicked');
    setModal(constants.MODAL_TYPES.SCHEDULE);
  }

  return isDesktop ? (
    <NavbarDesktop
      loading={loading}
      id={id}
      allowSchedule={allowSchedule}
      openScheduleModal={openScheduleModal}
      navOptions={desktopOptions}
    />
  ) : (
    <NavbarMobile
      loading={loading}
      id={id}
      navMainOptions={mainOptions}
      navAdditionalOptions={additionalOptions}
    />
  );
};

Navbar.propTypes = {
  id: PropTypes.string.isRequired,
  loading: PropTypes.bool,
};

export default Navbar;
