export default [
  {
    question:
      'Why choose RenoFi Design first over a traditional Architect or Interior Designer?',
    answer:
      'RenoFi Design delivers professional 3D conceptual designs and a cost estimate in under two weeks for a fraction of the cost of Traditional Architects and Interior Designers. Our design package is perfect for inviting contractors to provide an estimate for your project.',
    open: false,
  },
  {
    question: 'How much does it cost?',
    answer:
      'The initial consultation call is FREE, with no commitment required. Our conceptual design and cost estimate package starts at $899 plus tax. This price includes the design of one major space (e.g. kitchen, master bathroom, master bedroom, etc.). Multiple major spaces may result in higher costs. Schedule a call to learn more.',
    open: false,
  },
  {
    question: 'Why do I need to scan my property?',
    answer:
      'An existing floor plan provides our designers with essential information about your home that forms the basis for a well-informed design. Using your mobile device and a scanning app is both a convenient and affordable solution for creating a floor plan.',
    open: false,
  },
  {
    question: 'Is RenoFi Designs suitable for my renovation project?',
    answer:
      "RenoFi Designs is suitable for most renovation projects where spatial planning is required. <a href='#' id='reno-design-faq-cta' class='renofi-design-open-schedule'>Schedule a FREE, no-commitment consultation call</a> to determine if your project is a good fit!",
    open: false,
  },
];
