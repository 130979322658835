import {useState} from 'react';

import {Box} from 'rebass';

import {sendEvent} from '@renofi/analytics';
import {useScreenSize} from '@renofi/utils';
import {Button, Textarea, CheckboxPanel} from '@renofi/components';
import {sessionUrl} from '@renofi/analytics/src/datadog';

import {FeedbackTitle, FeedbackSubtitle, ButtonWrapper} from './styled';

const Feedback = ({
  title = "Looks like you haven't scheduled a call!",
  subtitle = "What's preventing you from scheduling a call today? Please select on of the options below and feel free to provide additional feedback.",
  optionsList = [],
  onFeedbackSubmit,
}) => {
  const {isMobile} = useScreenSize();
  const [feedback, setFeedback] = useState(null);
  const [feedbackDetails, setFeedbackDetails] = useState('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const onToggle = (feedbackSelected) => {
    setFeedback(feedbackSelected);
    setIsSubmitDisabled(false);
  };

  const onSubmit = () => {
    sendEvent('Feedback-Submitted', {
      sessionReplayUrl: sessionUrl(),
      logrocketSession: sessionUrl(), // temp to keep back compatibility,
      closeCalendarFeedback: feedback,
      closeCalendarFeedbackDetails: feedbackDetails,
    });
    onFeedbackSubmit(feedback, feedbackDetails);
  };

  return (
    <Box css={{padding: isMobile ? '60px 20px' : 40}}>
      <FeedbackTitle>{title}</FeedbackTitle>
      <FeedbackSubtitle>{subtitle}</FeedbackSubtitle>
      {optionsList.map((option, index) => (
        <CheckboxPanel
          key={index}
          label={option}
          onToggle={() => onToggle(option)}
          value={feedback === option}
        />
      ))}
      {!isSubmitDisabled && (
        <Textarea
          css={{resize: 'none', marginTop: '10px'}}
          autoFocus
          placeholder="Please provide additonal details"
          value={feedbackDetails}
          onChange={setFeedbackDetails}
        />
      )}
      <ButtonWrapper>
        <Button
          disabled={isSubmitDisabled}
          css={{marginTop: ['30px', '70px'], width: '200px'}}
          onClick={onSubmit}>
          Submit
        </Button>
      </ButtonWrapper>
    </Box>
  );
};

export default Feedback;
