import React, {useEffect, useState} from 'react';

import {isNil, size} from 'lodash';
import {Box} from 'rebass';
import {
  useLocation,
  useRouteMatch,
  useParams,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';
import {useQuery, useReactiveVar} from '@apollo/client';
import PropTypes from 'prop-types';

import {useTurndownMatches} from '@renofi/utils/src/turndowns';
import {useUnleashContext} from '@renofi/utils/src/feature-flags';
import {
  isQueryParamSet,
  daysPassed,
  getDomain,
  useCookies,
  deleteCookie,
  isUncompletedLendingTreeLead,
  usePrepareUnleashContext,
} from '@renofi/utils';
import {setAnalyticsIdentify, showSurvey} from '@renofi/analytics';
import {useRenovationScenario} from '@renofi/api';
import {
  isAuthenticated,
  DASHBOARD_REDIRECT_READY_KEY,
  LEAD_SESSION_DAYS,
  isLendingTreeLead,
} from '@renofi/utils/src/lead';
import {CookieConsentModal} from '@renofi/components';
import {LENDING_TREE_PRODUCTS} from '@renofi/api/src/queries/renovationScenario';

import {initLogAnalytics} from '../analytics';
import useSchedule from '../hooks/useSchedule';
import Calculator from '../Calculator';
import LoanOptions from '../LoanOptions';
import Rates from '../Rates';
import {Navbar, Container} from '../components';
import {leadVar, loanProductsVar} from '../api/cache';
import Home from '../Home';
import ScheduleModals from '../components/Schedule/ScheduleModals/ScheduleModals';
import SchedulePage from '../SchedulePage/SchedulePage';
import Design from '../Design';
import ContractorCheck from '../ContractorCheck';
import FindContractor from '../FindContractor';
import ProjectDetailsModal from '../ProjectDetailsModal';
import {onScenarioUpdate} from '../api/callbacks';
import LendingTree from '../LendingTree';
import Opportunity from '../Opportunity/Opportunity';
import LoanOptionsSkeleton from '../LoanOptions/LoanOptionsSkeleton';

function App({cookieId}) {
  const params = useParams();
  const [initialParams] = useState(new URLSearchParams(window.location.search));

  const {_, updateCookie} = useCookies('renofi_id');
  const [showProjectDetailsModal, setShowProjectDetailsModal] = useState(false);
  const [identified, setIdentified] = useState(false);
  const [opportunityChecked, setOpportunityChecked] = useState(false);

  const lead = useReactiveVar(leadVar);

  const loanProducts = useReactiveVar(loanProductsVar);
  const calculatorProductMatch = useRouteMatch(
    '/:id/calculator/product/:productId',
  );
  const calculatorMatch = useRouteMatch('/:id/calculator');
  const isCalculatorPage =
    calculatorProductMatch?.isExact || calculatorMatch?.isExact;

  const {productId} = calculatorProductMatch?.params || {};
  const [initialProductId] = useState(productId);
  const location = useLocation();

  const returningParam = isQueryParamSet('returning');
  const lastVisitedTresholdPassed = daysPassed(60, lead.dashboardLastVisitedAt);

  const updateContext = useUnleashContext();
  const contextReady = usePrepareUnleashContext(updateContext, lead);

  const {checking: checkingTurndowns} = useTurndownMatches(
    lead,
    loanProducts,
    !(lead?.firstName && contextReady),
  );
  const id = params?.id || cookieId;
  const queryScenario = useRenovationScenario({
    onCompleted: onScenarioUpdate,
    id,
  });
  const [loanPassLoading, setLoanPassLoading] = useState(true);
  const {refetch: fetchLendingTree} = useQuery(LENDING_TREE_PRODUCTS, {
    variables: {id: lead.id},
    skip: true,
  });
  const isUncompletedLendingTree = isUncompletedLendingTreeLead(lead);

  const scheduleUtils = useSchedule();
  const designScheduleUtils = useSchedule('design');

  const isLoading =
    !lead?.id ||
    !identified ||
    (process.env.REACT_APP_ENV !== 'test' && !contextReady) ||
    (process.env.REACT_APP_ENV !== 'test' && checkingTurndowns) ||
    !opportunityChecked;

  useEffect(() => {
    if (!id) return;
    queryScenario({id});
  }, [id]);

  useEffect(() => {
    if (id && !isNil(loanProducts.length) && lead.dashboardNextStep !== 'pos') {
      updateCookie({
        value: id,
        numberOfDays: LEAD_SESSION_DAYS,
        domain: getDomain(),
      });

      if (!isAuthenticated(id)) {
        deleteCookie(DASHBOARD_REDIRECT_READY_KEY);
      }
    }
  }, [id, loanProducts?.length]);

  useEffect(() => {
    if (lead?.id) {
      setIdentified(true);
      showSurvey({
        ...lead,
        properties: {
          id: lead?.id,
          stars: lead?.stars,
        },
      });
    }
  }, [lead?.id]);

  useEffect(() => {
    if (returningParam || lead.editProject || lastVisitedTresholdPassed) {
      setShowProjectDetailsModal(true);
    }
  }, [returningParam, lead.editProject, lastVisitedTresholdPassed]);

  useEffect(() => {
    (async () => {
      if (!lead.id) return;

      const isLendingTreeRedirect = initialParams?.get('loan_product_id');

      if (isLendingTreeRedirect || isUncompletedLendingTree) {
        const {data} = await fetchLendingTree();
        const loanPassMatches = data?.result?.loanPassMatches || {};
        onScenarioUpdate({result: {...lead.cache, loanPassMatches}});
      }

      setLoanPassLoading(false);
    })();
  }, [lead.id]);

  function onInitLogAnalytics() {
    initLogAnalytics();
    setAnalyticsIdentify(lead, {
      id: lead.id,
      loanProducts,
    });
  }

  return (
    <LendingTree initialParams={initialParams}>
      <Opportunity lead={lead} onCompleted={() => setOpportunityChecked(true)}>
        <Box height="100%">
          <Container mb={56} id="container">
            <Navbar id={id} loading={isLoading || loanPassLoading} />

            <Calculator
              initialProductId={initialProductId}
              active={isCalculatorPage}
            />

            <Switch>
              <Route exact path="/:id/home-value">
                <Home loading={isLoading} />
              </Route>

              <Route exact path={['/', '/:id']}>
                <Redirect to={`/${id}/loan-options${location.search}`} />
              </Route>

              <Route exact path="/:id/loan-options">
                {isLoading || loanPassLoading ? (
                  <LoanOptionsSkeleton />
                ) : (
                  <LoanOptions scheduleUtils={scheduleUtils} />
                )}
              </Route>

              <Route exact path="/:id/schedule">
                <SchedulePage loading={isLoading} />
              </Route>

              <Route exact path="/:id/design">
                <Design loading={isLoading} />
              </Route>

              <Route exact path="/:id/contractor-check">
                <ContractorCheck loading={isLoading} />
              </Route>

              <Route exact path="/:id/rates">
                {size(lead) ? <Rates /> : null}
              </Route>

              <Route exact path="/:id/find-contractor">
                <FindContractor loading={isLoading} />
              </Route>
            </Switch>
          </Container>
        </Box>

        <ProjectDetailsModal
          show={showProjectDetailsModal}
          lead={lead}
          onClose={() => setShowProjectDetailsModal(false)}
        />

        <ScheduleModals
          lead={lead}
          scheduleUtils={scheduleUtils}
          designScheduleUtils={designScheduleUtils}
        />

        {!isLoading ? (
          <CookieConsentModal
            initLogAnalytics={onInitLogAnalytics}
            isLead={Boolean(lead?.id)}
            isLendingTreeLead={isLendingTreeLead(lead)}
          />
        ) : null}
      </Opportunity>
    </LendingTree>
  );
}

App.propTypes = {
  id: PropTypes.string,
};

export default App;
