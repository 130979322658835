import React, {useState} from 'react';

import PropTypes from 'prop-types';
import {Box, Flex} from 'rebass';

import {
  isLeadQualified,
  usePrimaryAndSecondaryProducts,
  isIntroducedByContractor,
} from '@renofi/utils';
import {Toggle} from '@renofi/components';
import {sendEvent} from '@renofi/analytics';

import Option from './Option';
import {OptionsWrapper} from './styled';
import ComingSoonOption from './ComingSoonOption';
import GCOptions from './GCOptions';
import SecondaryOptions from './SecondaryOptions/SecondaryOptions';

function ProductOptions({lead, options}) {
  const [showSecondary, setShowSecondary] = useState(false);

  const single = options.length === 1;
  const {
    leadExternalOriginType,
    inLicensedState,
    state,
    loanAmountDesiredForRenovation,
  } = lead;
  const isContractorIntro = isIntroducedByContractor(lead);
  const displayUnlicensedStateLoanProduct =
    loanAmountDesiredForRenovation <= 150000;
  const isGeneralContractorLead = leadExternalOriginType === 'contractor';
  const isGeneralContractorView =
    isGeneralContractorLead && !isContractorIntro && single;
  const isQualified = isLeadQualified(lead);
  const {primaryOptions, secondaryOptions} =
    usePrimaryAndSecondaryProducts(options);

  function onSecondaryClick() {
    setShowSecondary(true);
    sendEvent('POS/Purchase-Mortgage-Needed-Clicked');
  }

  return (
    <>
      {single && (
        <Flex
          mt={['4px', 18]}
          flexDirection={['column', 'row']}
          alignItems="start"
          height="calc(100% - 250px)"
          justifyContent="center"
          css={{
            borderBottom: isGeneralContractorView && '1px solid #ebebeb',
            borderTop: isGeneralContractorView && '1px solid #ebebeb',
          }}>
          <Toggle show={isGeneralContractorView}>
            <GCOptions
              isQualified={isQualified}
              options={options}
              lead={lead}
            />
          </Toggle>

          <Toggle show={!isGeneralContractorView}>
            {inLicensedState && (
              <OptionsWrapper data-testid="single-non-gc-view" width={[1, 1]}>
                <Option
                  lead={lead}
                  qualified={true}
                  options={options}
                  option={options[0]}
                />
              </OptionsWrapper>
            )}
            {!inLicensedState && (
              <OptionsWrapper data-testid="single-non-gc-view">
                {displayUnlicensedStateLoanProduct && (
                  <Option
                    lead={lead}
                    qualified={true}
                    options={options}
                    option={options[0]}
                  />
                )}
                {!isContractorIntro && (
                  <ComingSoonOption
                    state={state}
                    single={!displayUnlicensedStateLoanProduct}
                    cashDisbursementType={options[0].cashDisbursementType}
                  />
                )}
              </OptionsWrapper>
            )}
          </Toggle>
        </Flex>
      )}

      <Toggle show={!single}>
        <Box mt={['4px', 18]}>
          <OptionsWrapper data-testid="non-single-view">
            {primaryOptions.length > 0 &&
              primaryOptions.map((option, index) => (
                <Option
                  qualified={isQualified}
                  key={index}
                  lead={lead}
                  options={options}
                  option={option}
                />
              ))}
          </OptionsWrapper>
        </Box>
      </Toggle>

      <Toggle show={!isGeneralContractorView}>
        <SecondaryOptions
          onClick={onSecondaryClick}
          lead={lead}
          options={options}
          secondaryOptions={secondaryOptions}
          show={showSecondary}
          primaryOptions={primaryOptions}
          isQualified={isQualified}
        />
      </Toggle>
    </>
  );
}

ProductOptions.propTypes = {
  options: PropTypes.array.isRequired,
  lead: PropTypes.object.isRequired,
  singleOptionWidth: PropTypes.number,
  isGeneralContractorLead: PropTypes.bool,
};

export default ProductOptions;
