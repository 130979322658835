import {breakpoint} from '@renofi/utils';
import {initDataDog, isTestSession} from '@renofi/analytics';
import {initSegment} from '@renofi/analytics/src/segment';
import {initSentry} from '@renofi/analytics/src/sentry';
import {initAccessibe} from '@renofi/analytics/src/accessibe';
import {initDelighted} from '@renofi/analytics/src/delighted';

const isMobile = window.matchMedia(`(max-width: ${breakpoint - 1}px)`).matches;
export const initAnalytics = () => {
  if (isTestSession()) {
    return;
  }
  initSegment();
  initAccessibe();

  if (!isMobile) initDelighted('s47jLzX1PXrEtFfk', 'delighted');
};

export const initLogAnalytics = () => {
  initDataDog();

  if (isTestSession()) {
    return;
  }

  initSentry();
};
