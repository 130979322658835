import {useEffect} from 'react';

import {toggleSurvey} from '@renofi/analytics';
import {constants} from '@renofi/utils';

export default function useToggleSurvey(modal) {
  useEffect(() => {
    if (modal === constants.MODAL_TYPES.NONE) {
      toggleSurvey('show');
    } else {
      toggleSurvey('hide');
    }
  }, [modal]);
}
