/* eslint-disable max-lines */
import React, {useEffect, useState} from 'react';

import {every, isEqual, isNil} from 'lodash';
import {Text, Box, Flex} from 'rebass';
import {useReactiveVar} from '@apollo/client';

import {
  AddressField,
  Button,
  CurrencyField,
  Label,
  Toggle,
} from '@renofi/components';
import {useHomeValuationEstimate} from '@renofi/api';
import {sendEvent} from '@renofi/analytics';
import {formatAddress} from '@renofi/utils/src/format';
import {
  isAddressValid,
  replaceWithUSPSAbbreviations,
  useLocalStorage,
  useScreenSize,
} from '@renofi/utils';

import {Panel, PanelTitle} from '../../components';
import {leadVar, setLead} from '../../api/cache';
import HomeValues from '../HomeValues';

import AddressInputs from './AddressInputs';
import {SmallLink} from './styled';

const HomeValuesPanel = () => {
  const [loading, setLoading] = useState(false);
  const {isDesktop} = useScreenSize();
  const lead = useReactiveVar(leadVar);
  const {estimatedHomeValue, adjustedHomeValue} = lead;
  const {city, homeValue, state, county, streetAddress, zipCode} = lead;
  const location = {city, county, state, streetAddress, zipCode};

  const [locationHolder, setLocationHolder] = useState(location);
  const [searchValue, setSearchValue] = useState('');
  const [errors, setErrors] = useState({});
  const queryHomeValue = useHomeValuationEstimate();
  const [storedValue, setStoredValue] = useLocalStorage(
    'renofi:adjustedHomeValue',
    estimatedHomeValue || homeValue || null,
  );

  const noEstimation = !storedValue && !estimatedHomeValue && !homeValue;
  const [status, setStatus] = useState(noEstimation ? 'auto' : 'idle');

  useEffect(() => {
    if (status) {
      setSearchValue('');
      setLocationHolder(location);
    }

    if (status === 'manual') {
      setLead({adjustedHomeValue: null});
    }
  }, [status]);

  function isFormValid(newLocation) {
    if (status === 'manual' && adjustedHomeValue) return true;

    return Boolean(
      !isEqual(newLocation, locationHolder) &&
        every(errors, isNil) &&
        isAddressValid(newLocation || location),
    );
  }

  function onChange(key, value, err) {
    if (['zipCode', 'adjustedHomeValue'].includes(key)) {
      setLead({[key]: value});
    } else {
      setLead({location: {...location, [key]: value}});
    }
    setErrors({...errors, [key]: err});
  }

  function onStreetChange(value) {
    setSearchValue(value);
  }

  async function onAddressSelect(newLocation) {
    setSearchValue(formatAddress(newLocation));
    await submitHomeValueEstimation(newLocation);
    saveAddress(newLocation);
  }

  function onCancel() {
    setLead({location: locationHolder});
    setStatus(noEstimation ? 'auto' : 'idle');
  }

  function onEdit() {
    setStatus('auto');
  }

  function onValueSave() {
    setStoredValue(adjustedHomeValue);
    setStatus('idle');
  }

  async function onAddressSave(newLocation = location) {
    setLocationHolder(location);
    const data = saveAddress(newLocation);

    if (!isFormValid(newLocation)) {
      return setStatus('edit');
    }

    if (!isEqual(newLocation, locationHolder)) {
      await submitHomeValueEstimation(data);
    }
  }

  function saveAddress(newLocation) {
    const {streetAddress} = newLocation;
    const data = {
      ...newLocation,
      streetAddressShort: replaceWithUSPSAbbreviations(streetAddress),
    };
    setLead({location: data});
    return data;
  }

  async function submitHomeValueEstimation(newLocation) {
    setLoading(true);
    const address = formatAddress(newLocation, {short: true});

    try {
      sendEvent('Dashboard/Plunk-Estimation-Request', {address: newLocation});
      const rsp = await queryHomeValue({address});
      const {valueDollars} = rsp?.data?.homeValuationEstimate || {};
      if (valueDollars) {
        sendEvent('Dashboard/Plunk-Estimation-Success', {
          plunkValue: valueDollars,
        });
        setStatus('idle');
        setStoredValue(valueDollars);
      } else {
        setStatus('manual');
        sendEvent('Dashboard/Plunk-Estimation-Empty', {address: newLocation});
      }
      setLead({estimatedHomeValue: valueDollars || null});
      setLoading(false);
      return valueDollars;
    } catch (e) {
      sendEvent('Dashboard/Plunk-Estimation-Failed');
    }

    setLoading(false);
    return null;
  }

  return (
    <>
      <Toggle
        show={status === 'edit' || status === 'auto' || status === 'manual'}>
        <Panel
          actionMobileBottom
          css={{overflow: 'visible', paddingBottom: [76, 16]}}
          action={
            <Flex width="100%">
              {(status === 'edit' || status === 'manual') && (
                <Button
                  mr={16}
                  mb={[24, 0]}
                  disabled={!isFormValid(location) || loading}
                  xxSmall={isDesktop}
                  small={!isDesktop}
                  internal
                  loading={loading}
                  onClick={() =>
                    status === 'edit' ? onAddressSave(location) : onValueSave()
                  }>
                  Save
                </Button>
              )}
              {noEstimation && status === 'auto' ? null : (
                <Button
                  xxSmall={isDesktop}
                  small={!isDesktop}
                  secondary
                  onClick={onCancel}>
                  Cancel
                </Button>
              )}
            </Flex>
          }>
          <PanelTitle mb={[32, 11]}>Property value</PanelTitle>

          <Flex alignItems={['flex-start', 'center']} flexDirection="column">
            <Text
              mt={16}
              mb={24}
              fontSize={24}
              textAlign={['left', 'center']}
              px={[0, 36]}
              maxWidth={660}
              lineHeight="28px">
              {status === 'manual'
                ? "We couldn't automatically estimate your home value. Please enter estimated value below."
                : 'Where is the project taking place?'}
            </Text>

            <Toggle show={status === 'auto'}>
              <Box width={['100%', 520]} pb={16}>
                <AddressField
                  loading={loading}
                  label="Enter and search the property address"
                  xLarge
                  value={searchValue}
                  onClickManual={() => setStatus('edit')}
                  onSelect={onAddressSelect}
                  onChange={(value, err) => onStreetChange(value, err)}
                />
              </Box>
            </Toggle>

            <Toggle show={status === 'edit'}>
              <SmallLink onClick={() => setStatus('auto')}>
                Switch to automatic search
              </SmallLink>

              <Box mb={16} width="100%">
                <AddressInputs
                  loading={loading}
                  formDisabled={!isFormValid(location)}
                  onSave={onAddressSave}
                  onChange={onChange}
                  adjustedHomeValue={adjustedHomeValue}
                  {...location}
                />
              </Box>
            </Toggle>

            <Toggle show={status === 'manual'}>
              <Flex justifyContent="center" width={['100%', 267]}>
                <Box width="100%">
                  <Label small htmlFor="adjustedHomeValue" mt={26}>
                    Property value
                  </Label>
                  <CurrencyField
                    mb={36}
                    topMessage="Required"
                    required
                    icon
                    value={adjustedHomeValue}
                    onChange={(value, err) =>
                      onChange('adjustedHomeValue', value, err)
                    }
                    id="adjustedHomeValue"
                    name="adjustedHomeValue"
                    type="text"
                  />
                </Box>
              </Flex>
            </Toggle>
          </Flex>
        </Panel>
      </Toggle>

      <Toggle show={status === 'idle'}>
        <Panel
          action={
            <Button xxSmall internal onClick={onEdit}>
              Edit
            </Button>
          }>
          <PanelTitle>Property value</PanelTitle>

          <Flex flexDirection={['column', 'row']}>
            <HomeValues loading={loading} storedValue={storedValue} />
          </Flex>
        </Panel>
      </Toggle>
    </>
  );
};

export default HomeValuesPanel;
