import {useReactiveVar} from '@apollo/client';

import {usePrevious} from '@renofi/utils';

import {modalVar, setModal} from '../api/cache';

export default function useModals() {
  const modal = useReactiveVar(modalVar);
  const prevModal = usePrevious(modal);

  return {
    setModal,
    modal,
    prevModal,
  };
}
