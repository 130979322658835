import React from 'react';

import PropTypes from 'prop-types';

import {SingleOptionWrapper} from '../styled';
import GCLeadOption from '../GCLeadOption';

const GCOptions = ({isQualified, lead, options}) => {
  return (
    <>
      <SingleOptionWrapper
        data-testid="single-gc-view"
        width={[1, isQualified ? 1 / 2 : 1]}
        py={[0, isQualified ? 10 : 36]}>
        <GCLeadOption lead={lead} options={options} option={options[0]} />
      </SingleOptionWrapper>
    </>
  );
};

GCOptions.propTypes = {
  isQualified: PropTypes.bool,
  lead: PropTypes.object,
  salesCard: PropTypes.node,
  options: PropTypes.array,
};

export default GCOptions;
